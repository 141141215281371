import { createContext } from 'react';

const AppContext = createContext({
    loggedin: false,
    loginInfo: {},
    profile_picture: '',
    token: '',
    routes: [],
    messageCount: ""
});

export default AppContext;