import axios from "axios";

const apiURL = "https://v5api.thap.in/thapV5";
// const apiURL = "https://stagingapi.thap.in/thapV5";
// const apiURL = "http://localhost:5500";
console.log(apiURL);
const API = axios.create();

API.interceptors.request.use(
  async (config) => {
    const authToken =
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("token"));

    config.headers.Authorization = "Bearer " + authToken || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (
      response.data.status === 401 &&
      response.data.message === "Unauthorized access"
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_details");
      localStorage.removeItem("loggedIn");
      window.location.href = "/login"; // Redirect to login page if token expires
    }
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export const GetAPI = async (url, callback) => {
  await API.get(apiURL + url)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      // callback(error.response.data);
    });
};

export const PostAPI = async ({ url, data }, callback) => {
  await API.post(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error);
      console.log(error);
    });
};

export const PatchAPI = async ({ url, data }, callback) => {
  await API.patch(apiURL + url, data)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      callback(error.response.data);
    });
};

export const DeleteAPI = async ({ url, data }, callback) => {
  await API.delete(apiURL + url, { data: data })
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      callback(error.response.data);
    });
};

export const Delete_params = async (url, callback) => {
  await API.delete(apiURL + url)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      callback(error.response.data);
    });
};

export const PutAPI = async ({ url, data }, callback) => {
  await API.put(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error);
      console.log(error);
    });
};
